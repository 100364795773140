/* HONEYCOMB DEFAULTS */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p,
li,
label {
  font-size: 1.5rem;
}

/* CUSTOM STYLES */

html,
body,
#root,
#experiment-window {
  text-align: center;
  margin: 0;
  min-height: 100vh;
  height: 100% !important;
  width: 100vw !important;
  background-color: #3da7c2;
  color: white;
}

#experiment-window {
  font-family: "Motley Forces", sans-serif;
}

.centered-h-v {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5%;
}

.width-50 {
  width: 50%;
}

.width-100 {
  width: 100%;
}
