/******************** FIXATION DOT ********************/

/* Custom styling for the fixation dot */
#fixation-dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: black;
}

/******************** PHOTODIODE ********************/

/* Custom styling for the photodiode box */
#photodiode-box {
  position: fixed;
  height: 50px;
  width: 50px;
  bottom: 3px;
  right: 3px;
  display: flex;
  margin: auto;
  outline: 3px solid rgb(80, 80, 80);
}

/* Custom styling for the photodiode spot */
#photodiode-spot {
  position: absolute;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: black;
}

/******************** FEEDBACK SCREEN ********************/

.feedbackScreen {
  position: relative;
}

.feedbackScreen h1 {
  margin: 0;
}

/* Containing div for the reward boxes */
.feedbackContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 75%;
  height: 50%;
}

/* Containing div for each individual reward */
.rewardContainer {
  background-color: rgba(0, 0, 0, 0.5); /* Optional background for better readability */
  height: 20%;
  padding: 0 15%;

  border-radius: 25px;
  background-color: #fcbb96;
  color: #de5700;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5%;
}

/* Custom styles for the first box  */
.rewardContainer:first-of-type {
  background-color: #de5700;
  color: white;
  font-size: 2em;
  height: 30%;
}

/* The label takes up double the width */
.rewardLabel {
  flex: 2;
}

/* The container for the reward value and image */
.rewardValue {
  flex: 1;
  height: 75%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
}
/* Reward value text */
.rewardValue > h1 {
  font-size: 3em;
}
/* Image after the reward value */
.rewardValue > img {
  height: 100%;
}
