/********** WRAPPER ELEMENTS **********/

/* Wrapper element for the entire screen */
#parentWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

/* Wrapper elements for the HUD */
#header,
#footer {
  background-color: #112a53;
  position: fixed;
  box-sizing: border-box;
  width: 100%;

  /* Header and footer are always exactly 150px tall */
  min-height: 150px;
  height: 150px;
  padding: calc(150px * 0.05); /* Padding is 5% of the height of the div */

  display: flex;
  align-items: center;
  gap: calc(150px * 0.05); /* Gap between children is 5% of the height of the div */
}
#header {
  top: 0;
}
#footer {
  bottom: 0;

  /* TODO: Loading this image from the public folder doesn't work ("module not found") */
  /* background-image: url("/assets/images/hud/motorcycle-dashboard.svg"); */
  /* background-size: cover; */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
}

/* Wrapper element for the game */
#gameWrapper {
  margin-top: -150px;

  /* Perform a 3D transformation to the #gameBoard */
  transform-style: preserve-3d;
  perspective: 400px;
}

/********** GAME ELEMENTS **********/

#gameBoard {
  width: 450px;
  height: 550px;
  background: #545454;
  margin: auto;
  top: 150px;

  transform-style: preserve-3d;
  transform: rotateX(90deg);

  /* Elements that are absolutely positioned in the game are relative to the board */
  position: relative;
}

#driverCar {
  width: 40px;
  height: 5px;
  position: absolute;
  transform-style: preserve-3d;
}

#driverScore {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateX(180deg);
  font-weight: bold;
}

.driverScore {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateX(180deg);
  font-weight: bold;
  padding-bottom: 20px;
}

#truckScore {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateX(180deg);
  font-weight: bold;
}

#backDriverCar {
  width: 40px;
  height: 50px;
  position: absolute;
  top: 5px;
  transform: rotateX(90deg);
  transform-origin: top;
}

#driverImage {
  width: 40px;
  height: 50px;
  transform: rotateX(180deg);
}

.highwayDivider {
  position: absolute;
  width: 5px;
  background: white;
}

#highwayPointer {
  position: absolute;
  width: 15px;
  height: 15px;
}
#targetCar {
  position: absolute;
  width: 40px;
  height: 5px;
  transform-style: preserve-3d;
}

#backTargetCar {
  width: 40px;
  height: 48px;
  position: absolute;
  transform: rotateX(90deg);
  transform-origin: top;

  display: flex;
  justify-content: center;
  align-items: center;
}

#targetImage {
  width: 40px;
  height: 50px;
  transform: rotateX(180deg);
}

/********** HUD ELEMENTS **********/

/* Containing divs for all elements in the header and footer */
.hudBox {
  width: 100%;
  height: 100%;
  flex: 1; /* Each div in the header takes up the same amount of space */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Text inside the header boxes */
.hudBox p {
  margin: 0;
  font-size: 2rem;
}

/* The images displayed in the header and footer */
.hudImage {
  height: 100%;
  width: auto;
}

/* The score elements (divs inside headerReward and headerLives)  */
.hudScore {
  display: flex;
  flex-direction: row;
  gap: 15%;
  align-items: center;
}

/* The images inside the score elements */
.hudScore > img {
  width: 50px;
  max-width: 50px;
}

#headerMission {
  color: #aae3ff;
}

#headerSign {
  background-color: gray;
  min-height: 120px;
  min-width: 120px;
  border-radius: 5%;
}

#footerAgent {
  flex: 2; /* The div of the footer fills all available width */
  max-height: 70%;
}

#footerFuel,
#footerSpeed {
  flex: 0;
}

/* Div containing the two gauges */
.gaugeBox {
  height: 100%;
  position: relative;
}

/* The needle image within the gauge */
.gaugeNeedle {
  position: absolute; /* Ensures needle is on top of the gauge */
  bottom: 0;
  max-height: 55%;
  left: 46.5%;
  transform: translate(-50%, 0);

  transform-origin: center 86%; /* Defines the center of the needles circle as the origin */
}

/********** UTILITY CLASSES **********/

/* Class that hides the element */
.invisible {
  visibility: hidden;
}
